import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import GoogleMaps from "../components/GoogleMaps";
import Reveal from "../components/Reveal";
import SiteMetadata from "../components/SiteMetadata";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";

// eslint-disable-next-line
export const ShowroomTemplate = ({
  gallery, 
  title, 
  description,
  metadata
}) => {

  return (
    <>
      <SiteMetadata {...metadata} />
        <div className="container-lg mt-5">
          <Reveal>
            <div className="section text-center">
                <div className="row">
                  <h1 className="title-2">{title}</h1>
                  <p className="description">{description}</p>
                </div>
            </div>
        </Reveal>
        <Reveal>
          <section className="section-b">
            <AliceCarousel
              mouseTracking
              autoPlayInterval={5000}
              infinite
              responsive={{
                0: { items: 1 },
                500: { items: 2 },
                961: { items: 3 },
                1222: { items: 3 },
                1555: { items: 3 },
              }}
              disableDotsControls={true}
              disableButtonsControls={true}
              autoPlay={false}
              items={gallery.map((item, i) => {
                return (
                    <div className="item" key={'showroom-carousel-item' + i}>
                      <PreviewCompatibleImage imageInfo={{image: item.image, alt: item.alt}} />
                    </div>
                  )
              })}
            />
          </section>
        </Reveal>
      </div>
      <Reveal>
        <section id="map">
          <GoogleMaps />
        </section>
      </Reveal>
    </>
  );
};

ShowroomTemplate.propTypes = {
  metadata: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.string,
  gallery: PropTypes.array,
};

const Showroom = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <Layout>
      <ShowroomTemplate
        metadata={frontmatter.metadata}
        gallery={frontmatter.gallery}
        title={frontmatter.title}
        description={frontmatter.description}
      />
    </Layout>
  );
};

Showroom.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default Showroom;

export const pageQuery = graphql`
  query ShowroomTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "showroom" } }) {
      frontmatter {
        metadata {
          title
          description
        }
        title
        description
        gallery{
          image {
            childImageSharp {
              gatsbyImageData(quality: 100, width:500, layout: CONSTRAINED, formats: [AUTO, WEBP, AVIF])
            }
          }
          alt
        }
      }
    }
  }
`;


